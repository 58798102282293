#loginPage {
    padding-top: 50px;
    //border: solid 1px blue;
  
    #err-msg {
      //border: solid 1px purple;
      color: $mpy-dark-alert;
      font-weight: bold;
      min-height: 1.5em;
      padding-bottom: .6em;
      //border: solid 1px grey;
    }
  
    form {
      display: inline-block;
    }
    > div:nth-child(1) {
      background: url('../images/granny.jpg') no-repeat center top;
      background-size: contain;
  
      //border: solid 1px green;
      display:inline-block;
      width: 50%;
      height: 400px;
      vertical-align: top;
      background-position-x:  50px;
    }
  
    > div:nth-child(2) {
      margin-left: 20px;
      text-align: left;
      display:inline-block;
      //border: 1px solid green;
      width:45%;
    }
  }
  
  .login-padding {
    margin-top: 20px;
  }
  