@import 'scss/_variables.scss';
@import 'scss/_components.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: medium;
  min-width: 425px;
  background: $mpy-snow;
}

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.imgSensorIssues          { background:  no-repeat  url('images/sensor-issues.svg'); }
.imgSystemConnected       { background:  no-repeat  url('images/system-connected.svg'); }
.imgSystemDisconnected    { background:  no-repeat  url('images/system-disconnected.svg'); }

.imgMovement              { background:  no-repeat  url('images/athome-movement.svg'); }
.imgNoMovement            { background:  no-repeat  url('images/athome-nomovement.svg'); }
.imgSleeping              { background:  no-repeat  url('images/athome-sleeping.svg'); }
.imgAway                  { background:  no-repeat  url('images/awayfromhome.svg'); }

.imgBehaviorStatusHigh    { background:  no-repeat  url('images/behavior-concern-high.svg'); }
.imgBehaviorStatusMedium  { background:  no-repeat  url('images/behavior-concern-medium.svg'); }
.imgBehaviorStatusLow     { background:  no-repeat  url('images/behavior-concern-none.svg'); }
.imgBehaviorStatusUnknown { background:  no-repeat  url('images/behavior-concern-unknown.svg'); }

.imgEnvStatusHigh         { background:  no-repeat  url('images/environment-concern-high.svg'); }
.imgEnvStatusMedium       { background:  no-repeat  url('images/environment-concern-medium.svg'); }
.imgEnvStatusLow          { background:  no-repeat  url('images/environment-concern-none.svg'); }
.imgEnvStatusUnknown      { background:  no-repeat  url('images/environment-concern-unknown.svg'); }

.imgPhysioStatusHigh      { background:  no-repeat  url('images/physiology-concern-high.svg'); }
.imgPhysioStatusMedium    { background:  no-repeat  url('images/physiology-concern-medium.svg'); }
.imgPhysioStatusLow       { background:  no-repeat  url('images/physiology-concern-none.svg'); }
.imgPhysioStatusUnknown   { background:  no-repeat  url('images/physiology-concern-unknown.svg'); }

.imgConcernLow            { background:  no-repeat  url('images/concern-none.svg'); }
.imgConcernMedium         { background:  no-repeat  url('images/concern-medium.svg'); }
.imgConcernHigh           { background:  no-repeat  url('images/concern-high.svg'); }
.imgConcernUnknown        { background:  no-repeat  url('images/concern-unknown.svg'); }

checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

#button-header {
  // height: 4em;                 // [ML] restore when add back monitoring levels
  // background: $mpy-dark-snow;  // [ML] restore when add back monitoring levels
  background: $mpy-light-smoke;  // [ML] restore when add back monitoring levels
  min-width: 450px;
  padding-top: 10px;

  .btn {
    padding-top: .15em;
  }
  .checkbox {
    display: inline-block;
    margin: 0;
    padding-top: .3em;
    padding-bottom: .4em;
    padding-right: .7em;
  }
  input[type='checkbox'] {
    margin-top: 2px;
  }
  div:nth-child(1) {
    >div {
      height: 1.6em;
      width: 50%;
      display: inline-block;
    }
  }
  .title {
    width: 50%;
    display: inline-block;
    text-align:left;
    padding-left: 4em;
    font-weight: bold;
  }
  .checkboxes {
      list-style-type: none;
      display: flex;
      flex-wrap:nowrap;
      justify-content: flex-end;
      align-items: center;
      margin-right: 4em;
      >li:nth-child(even) {
        margin-right: 20px;
      }
      >li:nth-child(odd) {
        margin-right: 7px;
      }
  }
  .attention-text {
    font-size: 12px;
  }
  .filter-button {
    color: $mpy-dark-silver;
    width: 23%;
    height: 30px;
    background: white;
    border: $mpy-smoke 1px solid;
    border-radius: 3px;
    cursor: pointer;
  }

  .selected {
    background: $mpy-blue;
    color: white;
  }
}

.MPY-card-container {
  width: 100%;
  text-align: left;
}

.MPY-caregiver-padding {
  padding-top: 2em;
}
// card colors
$mpy-card-container-bkg: $mpy-light-snow;
$mpy-card-body-bkg: white;
$mpy-card-border-color: $mpy-dark-silver;

.container-fluid {
  .row {
    margin: 10px auto -10px auto;
  }

  .col-md-6 {
    padding: 0 7px;
  }
}

.card-container {
  padding: 1px 0 15px 0;
  background: $mpy-card-container-bkg;

  .patient-card {
    background: $mpy-card-container-bkg;
    border: 1px solid $mpy-card-container-bkg;
    cursor: pointer;
  }

  .card-header, .card-footer {
    height: 27px;
    padding: 1px 18px 0 15px;
    font-size: medium;
    border: 1px solid $mpy-dark-silver;
  }

  .card-header {
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    color: white;
  }

  .card-footer {
    border-radius: 0 0 5px 5px;
    color: black;
    background-color: white;

    div {
      display: inline-block;
      text-align: center;
    }
    :nth-child(2) {
      float: right;
    }
  }

  .card-body {
    display: flex;
    height: 152px;
    border-right: 1px solid $mpy-card-border-color;
    border-left: 1px solid $mpy-card-border-color;
    padding: 0 !important;

    > div {
      width: 100%;
    }

    .patient-image {
      margin: 7px 5px 5px 5px;
      height: 8.5em;
      border: solid 1px white;
      border-radius: 5px;
    }

    .status-container,
    .status-container2 {
      height: 76px;
      padding: 5px 0 3px 0;
      width: 100%;
    }

    table {
      width: 100%;
      table {
        tr :nth-child(1) {
          text-align: right;
        }
        tr :nth-child(2){
          text-align: left;
        }
      }
    }

    td {
      width: 33%;
      vertical-align: middle;
      text-align: center;
    }
    td div {
      flex-direction: column;
      display: inline-flex;
    }

    .status-container2 {
      :nth-child(1) {
        width:33%;
      }
    }

    .status-block {height: 64px; width:64px; }
    .status-block2 {height: 32px; width:32px;}
  }

  .title-color-unknown  { background-color: $mpy-dark-smoke; }
  .title-color-positive { background-color: $mpy-positive; }
  .title-color-alert    { background-color: $mpy-alert; }
  .title-color-negative { background-color: $mpy-negative; }
}


#tableView {
  background: $mpy-snow;
  font-size: 1em;
  text-align: left;
  width: 100%;
  min-width: 850px;

  h3 { text-align: left; }

  .stateimg {
    width: 40px;
    height: 40px;
  }

  .pat-img {
    width: 60px;
    height: 60px;
    text-align: left !important;
    float: left;
  }
  .bg-green { background: $mpy-positive; }
  .bg-orange { background: $mpy-alert; }
  .bg-red { background: $mpy-negative; }

  .al-main {
    margin: 0 35px;
    padding: 10px 0 0;
    background-color: $mpy-snow;
  }

  .panel {
    color: #666;
    background-color: white;
    border: none;
    border-radius: 5px;
    position: relative;
    margin-bottom: 24px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1)
  }

  th, td {
    border: red 0 solid !important;
    text-align: center;
  }

  .align-left {
    text-align: left;
  }

  .panel-title {
    font-size: 1rem;
  }

  .panel.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s
  }

  .panel > .panel-body {
    padding: 15px 22px;
    height: 100%;
  }

  .panel > .panel-heading {
    color: #666;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 1em;
  }

  .panel-heading {
    margin-top: 1em;
    padding-top: 1em;
    height: 3em;
    position: relative
  }

  .panel-footer, .panel-heading {
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12)
  }

  .panel-footer, .panel-heading {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    font-size: 16px;
    padding: 1px 22px
  }

  .table {
    width: 100%;
  }

  .table > tbody > tr > td {
    vertical-align: middle
  }

  .table > tbody > tr.no-top-border:first-child > td {
    border-top: none
  }
  .table > thead > tr > th {
    border-bottom: 2px solid #ddd !important
  }

  .table > tbody > tr > td:first-child, .table > thead > tr > th:first-child {
    text-align: center
  }

  .table {
    margin-bottom: 0;
    margin-top: -10px !important;
  }

  .table > thead > tr > th {
    white-space: nowrap
  }

  .table > thead > tr > th:last-child {
    padding-right: 16px
  }

  .table > tbody > tr > tr:first-child {
    padding-top: 1px
  }

  .table > tbody > tr > td {
    padding: 2px 8px;
    line-height: 35px;
    border-top: 1px solid #dcdcdc
  }

  .table > tbody > tr > td:last-child {
    padding-right: 16px !important
  }

  .table .header-row th {
    vertical-align: middle;
    padding: 0 8px
  }

  .table > tbody > tr {
    cursor: pointer;
  }

  .container-fluid {
    margin: 0;
    .row {
      padding: 0;
    }
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

.sleepIcon { background: no-repeat  url('images/athome-sleeping.svg') ; }
.mealsIcon  { background: no-repeat  url('images/eating.svg') ; }
.activityIcon { background: no-repeat  url('images/dailyactivity.svg') ; }

#patient-detail {
  padding: 15px 15px 0 15px;
  background: $mpy-snow;

  .patient-card {
    border: 1px solid $mpy-snow;
    cursor: pointer;
    margin-bottom: 0;
  }

  .card-header {
    background: $mpy-blue;
    border: 1px solid $mpy-dark-silver;
    border-radius: 5px 5px 0 0;
    color: white;
    height: 27px;
    padding: 1px 18px 0 20px;
    font-size: medium;
    font-weight: bold;
    text-align: left;
  }
  .container-fluid { background-color: transparent; margin: 0; padding: 0;}

  .card-body, .ua-card-body {
    border-radius: 0 0 5px 5px;
    border: 1px solid $mpy-dark-silver;

    text-align: left;
    background-color: $mpy-card-body-bkg;
  }

  .card-body {
    padding: 10px 25px 25px 110px;
    margin-bottom: 15px;
  }

  .ua-card-body {
    padding: 10px 10px 25px 10px;
  }
  .unusual-activity-header {
    background: $mpy-alert;
  }

  .ua-record {
    padding: 5px 0 5px 0;

    .ua-date {
      display: inline-block;
      width: 20%;
    }
    .ua-msg {
      display: inline-block;
      width: 80%;
    }
  }
}
#external-dev-info {
  margin: 30px 20px;
}
#patientHeader {
  .title-color-unknown  { text-align: left; background-color: $mpy-dark-smoke; font-weight: bold; color: white; }
  .title-color-positive { text-align: left; background-color: $mpy-positive; font-weight: bold; color: white; }
  .title-color-alert    { text-align: left; background-color: $mpy-alert; font-weight: bold; color: white; }
  .title-color-negative { text-align: left; background-color: $mpy-negative; font-weight: bold; color: white; }
  background-color: white;

  .back-button {
    color: black;
    background: white;
    display: inline-block;
    font-size: small;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 3px;
    border: solid 1px white;
    padding: 2px 2px 2px 4px;
    box-shadow: 2px 2px #aaa;
  }

  .back-button:hover {
    color: inherit;
    background: inherit;
  }

  .back-button:active {
    box-shadow: 2px 0 #aaa;
    transform: translateY(2px);
  }

  .pheader-bar, .pheader-bar-with-backbtn {
    border-radius: 5px 5px 0 0;
    border: solid 1px $mpy-dark-smoke;
    height: 42px;
    font-size: 1em;
  }
  .pheader-bar-with-backbtn {
    padding: 8px 20px 0 10px;

  }
  .pheader-bar {
    padding: 8px 20px 0 20px;
  }

  .pheader-body {
    text-align: left;

    .patient-overview {

      display: inline-table;
      vertical-align: middle;
      text-align: left;
      width: 70%;
      height: 100%;
      margin: auto 10px auto 10px;
    }

    .patient-image, .overall-activity {
      display: inline-table;
      vertical-align: middle;
      width: 12%;
      min-width: 75px;
      max-width: 100px;

      img {
        width: 100%;
      }
    }

    .overall-activity {
      text-align: right;
    }
  }
}

#rxEventReport, #UEventReport, #deviceEventReport, #bLevelReport, #home-life-report {
  margin: .8em;

  .table > tbody > tr > td {
    vertical-align: middle
  }

  .table > tbody > tr.no-top-border:first-child > td {
    border-top: none
  }
  .table > thead > tr > th {
    border-bottom: 2px solid #ddd !important;
    text-align: left;
  }

  .table > tbody > tr > td:first-child,
  .table > thead > tr > th:first-child {
    text-align: left
  }

  .table > tbody > tr > td {
    text-align: left;
  }

  .table {
    margin-bottom: 0
  }

  .table > thead > tr > th {
    white-space: nowrap
  }

  .table > thead > tr > th:last-child {
    padding-right: 1em;
  }

  .table > tbody > tr > tr:first-child {
    padding-top: 1px
  }

  .table > tbody > tr > td {
    padding: 2px .5em;
    line-height: 1.25em;
    border-top: 1px solid #dcdcdc
  }

  .table > tbody > tr > td:last-child {
    padding-right: 1em !important
  }

  .table .header-row th {
    vertical-align: middle;
    padding: 0 .5em;
  }
}

#deviceEventReport {
  text-align: left;
  .eventBtnBlock {
    margin: 1em auto 1em auto;

    div:nth-child(3) {
      margin: .6em auto auto auto;
    }  }
  #eventBtnGroup {
    div {
      display: inline-block;
      margin: .1em auto auto auto;

    }
  }
}

.next-prev-btn-nav {
  margin: 10px 20px 10px 10px;
  padding: 15px;
  background: $mpy-dark-snow;
  font-size: larger;

  div {
    display: inline-block;
  }
  span {
    font-weight: bold;
  }
  > div:nth-child(2) {
    vertical-align: middle;
    float: right;
    font-weight: bolder;
    margin-top: -4px;

    button {
      margin-top: -1px;
      font-size: large;
    }
    button:disabled {
      color: gray;
    }
  }

}

#home-life-report {
  .hlr {
    .hlr-header1, .hlr-header2 {
      display: contents;
      > div {
        border-left: 1px solid $mpy-smoke;
      }
    }
    .hlrGrid, .hlr-grid {
      border: solid 1px $mpy-dark-smoke;
      margin-top: 25px;

      display: grid;
      grid-template-columns: 12% 18% repeat(9, 1fr);
      grid-template-rows: repeat(36, auto);
    }

    .hlr-header1 {
      display: contents;
      > div {
        padding: 2px;
        font-weight: bold;
        background: $mpy-light-smoke;
      }
    }

    .header-spacer {
      grid-column: 1/2;
      grid-row: 1/3;
      background: white !important;
      border-left: solid 1px white !important;
    }

    .recent-day-header {
      border-left: solid 1px $mpy-light-smoke !important;
      grid-column: 2/4;
      grid-row: 1/3;
      align-items: center;
      vertical-align: center;
    }

    .prev-week-header {
      grid-column: 4/6;
      grid-row: 1/2;
    }

    .prev-month-header {
      grid-column: 6/8;
      grid-row: 1/2;
    }

    .sleep-block, .eating-block, .activity-block, .temp-block, .med-block {
      display: contents;
      > div {
        padding: 5px;
        border-bottom: solid 1px lightgrey;
        border-left: solid 1px white;
        border-right: solid 1px white;
      }
      > div:nth-child(10n+2) {
        text-align: left;
      }
    }

    // center the group name
    .eating, .sleep, .recent-day-header, .activity, .home-temp, .med {
      display: grid;
      align-items: center;
      > div:nth-child(1) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }
    }

    .med-block {
      > div:nth-child(10n+2) {
        display: block;
        font-size: 14px;
        > div:nth-child(1) {
          display: inline-block;
          font-weight: bold;
        }
        > div:nth-child(2) {
          float: right !important;
        }
      }
      .more-med {
        display: block !important;
        text-align: right !important;
      }
    }
    // Alternating colors for block (sleep, activity & med).
    // In alternating block, we want the most recent data, prior week and
    // prior month highlighted;
    .sleep-block, .activity-block, .med-block {
      > div {
        background: lightgoldenrodyellow;
      }
      > div:nth-child(10n+3) {
        background: lightyellow;
      }
      > div:nth-child(10n+4), > div:nth-child(10n+5) {
        background: $mpy_light_positive;
      }
    }

    // Alternating other color for block
    .eating-block, .temp-block {
      > div {
        background: white;
      }
    }

    // These define the block names so they vertically span multiple rows.
    .sleep {
      grid-column: 1/2;
      grid-row: 3/7;
    }
    .eating {
      grid-column: 1/2;
      grid-row: 7/9;
    }
    .activity {
      grid-column: 1/2;
      grid-row: 9/14;
    }
    .temp {
      grid-column: 1/2;
      grid-row: 14/16;
    }
    .med {
      grid-column: 1/2;
      grid-row: 16/-1;
    }
  }
}

.error-block {
  margin: 15px;
}

.margin-bottom-16 {
  button {
    margin-bottom: 12px;
  }
}
