#notificationOptionsContainer {
    padding: 15px 15px 0 15px;

    #notification-options {
        padding: 10px 5px 10px 15px;
        text-align: left;

        .monitoring-levels {
            border: solid 1px $mpy-light-smoke;
            background: white;
            border-radius: 5px;
            margin: 0 -1.2em .6em -1.8em;
            padding: .9em .9em .2em .9em;

            div:nth-child(1) {
                margin: -.4em 0 .3em 0;
                padding: 0;
                font-weight: bold;
            }

            .radio {
                padding: 0;
                margin: 0 0 3px 0;
                color: $mpy-dark-silver;
            }
        }

        h4 {
            padding-top: 10px;
            font-weight: bolder;
            font-size: medium;
            color: $mpy-dark-silver;

            span {
                font-weight: normal;
                ;
                font-size: smaller;
            }
        }

        .submit-status {
            color: $mpy-blue;
            font-weight: bold;
            padding-left: 20px;
        }

        .patientOptionDisplay {
            background: white;
            border: solid 1px $mpy-light-smoke;
            border-radius: 5px;
            padding: 0 .9em 0 .9em;
            margin: 0 -1.2em .6em -1.8em;

            div:nth-child(1) {
                padding: .5em 0 0 0;
                font-weight: bold;
            }

            .optionsGroup {
                margin-bottom: 15px;
                font-weight: normal;
                color: $mpy-dark-blue;

                .checkbox,
                .radio {
                    padding: 0;
                    margin: 0;
                }

                .option-checkbox {
                    margin: 0;
                    padding: 0 0 0 20px;
					label {
						font-weight: normal;
						margin-bottom: 0px;
					}
                }

                .title1 {
                    font-weight: bold;
                }

                .title2 {
                    font-size: x-small;
                    font-weight: normal;
                }
            }

            .groupLabel {
                font-weight: bold;
                font-size: small;
                color: $mpy-dark-silver;
            }
        }
    }
}

// Modal box styles
#myModal {
	height: auto;
	.nobottom-margin {
		border-bottom: -20px;
	}
	.time-table {
		>div:nth-child(odd) {
			background-color: $mpy-light-smoke;
		}
		>div:nth-child(1) {
			background-color: white;
		}
		>div:last-child {
			border-bottom: 1px solid $mpy-light-smoke;
		}
		margin-bottom: 0;
	}
	.time-table-row,
	.time-table-headerrow {
		width: 100%;
		min-width:fit-content;
		margin-top: 5px;
		display: flex;
		align-content: center;

		>div {
			justify-self:center;
			align-self: center;
			padding-bottom: 1px;
			padding-top: 1px;
			text-align: center;
		}
		>div:nth-child(1) {
			width:20%;
		}
		>div:nth-child(2) {
			width:20%;
		}
		>div:nth-child(3) {
			width: 35%;
		}
		>div:nth-child(4) {
			width:20%;
		}
	}
	.time-table-headerrow {
		font-weight: bold;
	}
	input[type=text] {
	  width: 40px;
	}
	#input-control {
		margin-top: 15;
		margin-bottom: 0;
		display: grid;
		grid-template-columns: 10% 90%;
		grid-template-rows: 2em auto;
		>div {
			margin-bottom: 0;
		}
		>div:nth-child(odd) {
			margin-left: 20px;
			align-self: start;
			justify-self: center
		}
		label {
			width: 100%;
		}
	}
  }
  
  #options-mheader {
	background: $mpy-dark-blue;
	color: white;
	font-weight: bold;
  }
  
  .optionsHeader {
	background: $mpy-dark-blue;
	margin: 0;
	//padding: 0;
	text-align: left;
	height: 50px;
	padding: 8px 0 0 0;
  
	span {
	  padding: 0 0 0 30px;
	  color: white;
	  font-weight: bold;
	  font-size: 25px;
	}
  }
