$hm-nodata: #ffffff;
$hm-background: #eeeeee;
$hm-sleep1: #CCCCDE;
$hm-sleep2: #57CBF5;

$hm-meal1: #E6E6E6;
$hm-meal2: #DEDEDE;
$hm-meal3: #D6D6D6;
$hm-meal4: #CECECE;
$hm-meal5: #C6C6C6;
$hm-meal6: #BEBEBE;
$hm-meal7: #B6B6B6;
$hm-meal8: #AEAEAE;
$hm-meal9: #A6A6A6;
$hm-meal10: #009900;

$hm-act1: #FF9900;
$hm-act2: #FFAA00;
$hm-act3: #FFBB00;
$hm-act4: #FFCC00;
$hm-act5: #FFDD00;
$hm-act6: #FFEE00;
$hm-act7: #FFFF00;

#HeatMapDisplay {
    padding: 15px 15px 0 15px;
    background: $mpy-dark-snow;
    text-align: left;
  
    .hmNoData {
      background: $hm-nodata;
    }
    .hmBgd {
      background: $hm-background;
    }
  
    .rxBorderGood {
      border: solid 2px green !important;
    }
    .rxBorderBad {
      border: solid 2px red !important;
    }
  
    .heatmap {
      background: white;
      padding: 10px;
      margin: 10px;
      >div:nth-child(1) {
        margin-bottom: 5px;
      }
      >div:nth-child(2) {
        border: $mpy-dark-smoke 1px solid;
        border-radius: 3px;
        margin: 0
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
      }
  
      tr:nth-child(1) {
        background: #8ea0ba; /* Old browsers */
        background: -moz-linear-gradient(left, #8ea0ba 0%, #8ea0ba 15%, #ffffff 35%, #ffffff 65%, #8ea0ba 85%, #8ea0ba 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #8ea0ba 0%, #8ea0ba 15%, #ffffff 35%, #ffffff 65%, #8ea0ba 85%, #8ea0ba 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #8ea0ba 0%, #8ea0ba 15%, #ffffff 35%, #ffffff 65%, #8ea0ba 85%, #8ea0ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      th {
        height: 20px;
        vertical-align: middle;
        font-size: 11px;
        text-align: left;
      }
  
      th:nth-child(5) {
        font-size: 11px;
        vertical-align: bottom;
      }
  
      tbody {
        border: solid 1px white;
      }
  
      td {
        border-collapse: collapse;
        height: 40px;
      }
  
      @media only screen and (max-width: 320px) {
        td {
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          border-left: 1px solid white;
          border-right: 0 solid white;
        }
      }
      @media only screen and (min-width: 321px) and (max-width:480px) {
        td {
          min-width: 6px;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          border-left: 1px solid white;
          border-right: 0 solid white;
        }
      }
      @media only screen and (min-width: 481px) and (max-width:1200px) {
        td {
          min-width: 6px;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          border-left: 2px solid white;
          border-right: 2px solid white;
        }
      }
  
      @media only screen and (min-width: 1201px) {
        td {
          //min-width: 6px;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          border-left: 5px solid white;
          border-right: 4px solid white;
        }
      }
  
      .dev-tr {
        td {
          height: 8px;
        }
      }
  
      .hmSpacerRow {
        td {
          height: 2px;
          padding:0px;
          background: white;
          hr {
            margin: 1px;
            border-top: solid 1px $mpy-dark-smoke;

          }
        }
      }
  
      div:last-child {
        text-align: center;
      }
  
      .hm-outline {
        border: black 1px solid;
        width: 100%;
        height: 100%;
      }
  
      .caduceus, .caduceusDisabled {
        float: right;
        margin-bottom: 3px;
        cursor: pointer;
        color: $mpy-dark-alert;
        fill: currentColor;
  
        img {
          width: auto;
          height: 25px;
        }
      }
      .caduceusDisabled {
        cursor: default;
      }
  
      .hm-slp0 { background: $hm-nodata;}
      .hm-slp2 { background: $hm-background; }
      .hm-slp1 { background: $hm-sleep1; }
      .hm-slp3 { background: $hm-sleep2; }
  
      .hm-meal0 { background: $hm-nodata; }
      .hm-meal10 { background: $hm-background; }
      .hm-meal11 { background: $hm-meal1; }
      .hm-meal12 { background: $hm-meal2; }
      .hm-meal13 { background: $hm-meal3; }
      .hm-meal14 { background: $hm-meal4; }
      .hm-meal15 { background: $hm-meal5; }
      .hm-meal16 { background: $hm-meal6; }
      .hm-meal17 { background: $hm-meal7; }
      .hm-meal18 { background: $hm-meal8; }
      .hm-meal19 { background: $hm-meal9; }
      .hm-meal20 { background: $hm-meal10; }
      .hm-meal21 { background: $hm-meal10; }
      .hm-meal22 { background: $hm-meal10; }
      .hm-meal23 { background: $hm-meal10; }
      .hm-meal24 { background: $hm-meal10; }
      .hm-meal25 { background: $hm-meal10; }
  
      .hm-act0   { background: $hm-nodata; }
      .hm-act100 { background: $hm-background; }
      .hm-act101 { background: $hm-act1; }
      .hm-act102 { background: $hm-act2; }
      .hm-act103 { background: $hm-act3; }
      .hm-act104 { background: $hm-act4; }
      .hm-act105 { background: $hm-act5; }
      .hm-act106 { background: $hm-act6; }
      .hm-act107 { background: $hm-act7;}
    }
  
    #heat-legend {
      margin: .6em;
      text-align: left;
  
      #legend-dev-blk, #legend-rxdev-blk {
        display: inline-block;
        border: solid 1px $mpy-light-silver;
        border-radius: 4px;
        margin-top: .8em;
        max-width: 45%;
        vertical-align: top;
      }
  
      #legend-dev-blk {
        margin-right: 1.5em;
        min-width: 200px;
        > div:nth-child(2) {
          text-align: center;
          margin: .5em auto .25em auto;
        }
      }
  
      #legend-rxdev-blk {
        min-width: 275px;
        padding-top: .25em; 
        padding-bottom: .25em; 
        > div:nth-child(2) {
          font-size: smaller;
          margin: .5em .3em .1em .3em;
          border: solid 1px $mpy-dark-smoke;
        }
      }
  
      $ptrnClr: #cccccc;
      .rxPatternBgdGood, .rxPatternBgdBad {
        background-color: #333333;
        background-size: 3px 3px;
        background-image: -webkit-linear-gradient(45deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr),
        -webkit-linear-gradient(-45deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr);
        background-image: -moz-linear-gradient(45deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr),
        -moz-linear-gradient(-45deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr);
        background-image: linear-gradient(45deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr),
        linear-gradient(135deg, $ptrnClr 25%, transparent 25%, transparent 75%, $ptrnClr 75%, $ptrnClr);
      }
  
      .legend-device-row {
        margin: auto .3em auto .3em;
  
        > div:nth-child(1) {
          display: inline-block;
          width: 80%;
        }
        > div:nth-child(2) {
          float: right;
          // display: inline-block;
          margin: .2em auto auto auto;
          width: 1em;
          height: 1em;
          border: solid 1px $mpy-dark-smoke;
        }
      }
  
      #activity-levels {
        margin: auto .5em auto .5em;
  
        div {
          vertical-align: top;
          border: solid 1px white;
          height: 30px;
          width: 14.25%;
          display: inline-block;
  
          text-align: center;
          line-height: 240%;
          font-weight: bold;
          font-size: small;
        }
  
        div:nth-child(1) { background-color: $hm-act7 }
        div:nth-child(2) { background-color: $hm-act6 }
        div:nth-child(3) { background-color: $hm-act5 }
        div:nth-child(4) { background-color: $hm-act4 }
        div:nth-child(5) { background-color: $hm-act3 }
        div:nth-child(6) { background-color: $hm-act2 }
        div:nth-child(7) { background-color: $hm-act1 }
      }
    }
  }
