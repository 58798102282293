/**************
 *  Primary Colors
 */
 $mpy-light-blue:  #BEDCF0;
 $mpy-blue:        #2A80B9;
 $mpy-dark-blue:   #216593;
 
 /**************
  *  Secondary Colors
  */
 
 /**************
  *  Semantic Colors
  */
 $mpy-light-positive:  #ECF0BE;
 $mpy-positive:        #67AB00;
 $mpy-dark-positive:   #538C00;
 
 $mpy-light-alert:     #F7D5B7;
 $mpy-alert:           #E77E22;
 $mpy-dark-alert:      #BF6615;
 
 $mpy-light-snow:      #FBFBFB;
 $mpy-snow:            #F8F8F8;
 $mpy-dark-snow:       #F5F4F4;
 
 $mpy-light-smoke:     #E9E8E8;
 $mpy-smoke:           #DEDEDE;
 $mpy-dark-smoke:      #B8BCC0;
 
 $mpy-light-silver:    #899196;
 $mpy-silver:          #47535C;
 $mpy-dark-silver:     #333B42;
 
 $mpy-light-negative:  #FFCFCD;
 $mpy-negative:        #FF4D43;
 $mpy-dark-negative:   #D60A00;
 
 $logo-light-blue: #0abefa;
 
 $default_border_radius: 4px;

   // Form Fields
  // Colors
  $mpy-gray: #53565a;
  $mpy-light-gray: #A0A3A7;
  $mpy-focused: #0071c5;
  $mpy-input-error-border: $mpy-dark-alert;
  $mpy-input-border: solid 1px #cad2d4;
  
  // Animation
  $mpy-label-duration: 200ms;
  $mpy-label-initial: translateY(38px);
  $mpy-label-final: translateY(0);
